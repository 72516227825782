<template>
    <div class="case-box-con">
        <div class="case-con">
            <div class="case-sub">
                <div class="box">
                    <div class="sub-top">
                        <i class="sub-back" @click="onBreak"></i>
                        <img src="../../../assets/Case/top.jpg">
                    </div>
                    <div class="sub-text">
                        <h1 class="title">吉利汽车</h1>
                        <h3 class="title-sm">小红书推广</h3>
                        <p class="text">小红书平台的多元化内容输出，覆盖不同年龄段的受众群体，同时增加品牌的好感度，促进销售。</p>
                    </div>
                </div>
            </div>

            <div class="case-li">
                <div class="box">
                    <ul>
                        <li><img src="../../../assets/Case/content.jpg"></li>
                        <li><img src="../../../assets/Case/content.jpg"></li>
                        <li><img src="../../../assets/Case/content.jpg"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Content from './content'
export default Content
</script>
<style lang="less">
@import "content";
</style>
