
/**
 * content
 */
const Content = {
    name:'content',
    data() {
        return {
        }
    },
    created() {

    },
    methods: {
        onBreak(){
            this.$router.push('/')
            window.location.href = '#case'
        },
    }
}
export default Content
